<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Consider the TLC plate shown below. Note that the student did not place the 0 mark on the
        ruler at the base line (not even the base of the plate). Determine the
        <stemble-latex content="$R_f$" />
        value for each of the three spots on the plate. Please report your answers to 2 significant
        figures.
      </p>

      <p class="mb-2">
        <v-img style="max-width: 300px" src="/img/assignments/TLCPlateUCI51LB.png" />
      </p>

      <p class="mb-2">
        <calculation-input
          v-model="inputs.input1"
          prepend-text="Spot A:"
          :disabled="!allowEditing"
        />
      </p>

      <p class="mb-2">
        <calculation-input
          v-model="inputs.input2"
          prepend-text="Spot B:"
          :disabled="!allowEditing"
        />
      </p>

      <p class="mb-2">
        <calculation-input
          v-model="inputs.input3"
          prepend-text="Spot C:"
          :disabled="!allowEditing"
        />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '../inputs/CalculationInput.vue';

export default {
  name: 'ChemUCI51LB_A8Q4A',
  components: {StembleLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
    };
  },
};
</script>
